import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacings['12']};
  width: 100%;
`

const Price = styled(Text)`
  min-width: 60px;
  text-align: end;
`

export const Treatment = ({ treatment, price, bold }) => {
  return bold != null ? (
    <Layout>
      <Text font='Roboto' variant='large'>
        <Bold>{treatment}</Bold>
      </Text>
      <Price font='Roboto' variant='large'>
        <Bold>{price}</Bold>
      </Price>
    </Layout>
  ) : (
    <Layout>
      <Text font='Roboto' variant='large'>{treatment}</Text>
      <Price font='Roboto' variant='large'>
        <Bold>{price}</Bold>
      </Price>
    </Layout>
  )
}

Treatment.propTypes = {
  treatment: PropTypes.string,
  price: PropTypes.string,
  bold: PropTypes.bool,
}
